import { DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu';
import { DropdownMenu, DropdownMenuContent } from '@src/client/ui-library/dropdown';
import { cn } from '@src/client/ui-library/utils';
import { PropsWithChildren, ReactNode } from 'react';

import LucidIconWrapper from '../lucid-icon-wrapper';
import { SidepanelActiveNavClassNames, SidepanelNavLinkClassNames } from './constants';
import LinkInfoTooltip from './LinkInfoTooltip';

interface Props extends PropsWithChildren {
  isNavigationSidepanelOpen: boolean;
  triggerText: string;
  triggerIcon: ReactNode;
  isActive: boolean;
  tooltipContent?: React.ReactNode;
}

export default function NestedNavigationMenu({
  isNavigationSidepanelOpen,
  children,
  triggerText,
  triggerIcon,
  isActive,
  tooltipContent,
}: Props) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className={cn(
          `w-full bg-transparent`,
          SidepanelNavLinkClassNames,
          isNavigationSidepanelOpen ? 'p-2' : 'px-1 py-2 justify-center',
          isActive ? SidepanelActiveNavClassNames : '',
        )}
      >
        <LinkInfoTooltip content={tooltipContent ?? null} side="right" sideOffset={12}>
          <div className="w-full">
            {isNavigationSidepanelOpen ? (
              <div className="flex items-center justify-between w-full">
                <div className="inline-flex items-center space-x-2">
                  {triggerIcon}
                  <p className="text-sm font-medium">{triggerText}</p>
                </div>
                <LucidIconWrapper name="chevron-right" />
              </div>
            ) : (
              triggerIcon
            )}
          </div>
        </LinkInfoTooltip>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[260px] ml-2 p-2 shadow-none rounded-l-none" side="right">
        {children}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
