import {
  HeatmapIconV3,
  HomeIcon,
  PerceptIconSmall,
  PerceptIconText,
  SdkIntegrationIconV3,
  YourReportsIconV3,
} from '@client/ui-library/icons/v3';
import { useIsScopeAvailable } from '@src/client/helpers/access/hooks';
import { AccessScopes } from '@src/client/helpers/access/types';
import { useIsOnboardingCreatedWorkspace } from '@src/client/helpers/reports/hooks';
import {
  useIsDemoWorkspace,
  useIsExperimentEnabled,
  useIsFeatureFlagEnabled,
  useIsHeatMapEnabled,
  useNavigationLinkWithWorkspace,
} from '@src/client/hooks';
import { integratedSdksState } from '@src/client/modules/sdk-integration/atoms';
import { SdkType } from '@src/client/modules/sdk-integration/types';
import { userInfoState } from '@src/client/recoil/atoms';
import { Button } from '@src/client/ui-library/button';
import { CustomSvgIconActiveModeStroke } from '@src/client/ui-library/icons/v3/constants';
import { cn } from '@src/client/ui-library/utils';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import LucidIconWrapper from '../lucid-icon-wrapper';
import NavbarSearch from '../site-navbar/NavbarSearch';
import BoardsMenu from './BoardsMenu';
import CreateReportsMenu from './CreateReportsMenu';
import EngageMenu from './EngageMenu';
import EventsMenu from './EventsMenu';
import ExperimentsMenu from './ExperimentsMenu';
import SettingsMenuNew from './SetingsMenuNew';
import SidepanelNavLink from './SidepanelNavLink';
import SidepanelNavlinkTooltip from './SidepanelNavlinkTooltip';
import UserMenu from './UserMenu';
import WalletMenuNew from './WalletMenuNew';
import WorkspaceSelectorNew from './WorkspaceSelectorNew';

function PerceptIcon({ isNavigationSidepanelOpen }: { isNavigationSidepanelOpen: boolean }) {
  const { getLinkWithWorkspace } = useNavigationLinkWithWorkspace();

  return (
    <Link to={getLinkWithWorkspace('home')} className={isNavigationSidepanelOpen ? 'flex' : 'pl-1.5 flex'}>
      {isNavigationSidepanelOpen ? (
        <div className="inline-flex items-center space-x-0">
          <PerceptIconSmall />
          <PerceptIconText />
        </div>
      ) : (
        <PerceptIconSmall />
      )}
    </Link>
  );
}

export default function NavigationSidepanel() {
  const { pathname: currentPathname } = useLocation();
  const [userInfo] = useRecoilState(userInfoState);
  const integratedSdks = useRecoilValue(integratedSdksState);
  // const [isNavigationSidepanelOpen, setIsNavigationSidepanelOpen] = useState<boolean>(() => {
  //   const toggleStateInCache = localStorage.getItem('percept_ns_state');
  //   if (toggleStateInCache) {
  //     return JSON.parse(toggleStateInCache);
  //   }
  //   return false;
  // });

  const [isNavigationSidepanelOpen, setIsNavigationSidepanelOpen] = useState(true);
  const isExperimentEnabled = useIsExperimentEnabled();
  const isFeatureFlagEnabled = useIsFeatureFlagEnabled();
  const isHeatMapEnabled = useIsHeatMapEnabled();
  const isWorkspaceCreatedViaOnboardingFlow = useIsOnboardingCreatedWorkspace();
  const isWebSdkIntegrated = integratedSdks?.some((s) => s.sdkType.toLowerCase() === SdkType.WEB.toLowerCase());

  const canShowExperiments = isExperimentEnabled || isFeatureFlagEnabled || isWorkspaceCreatedViaOnboardingFlow;
  const canShowHeatmaps =
    isHeatMapEnabled ||
    (isWorkspaceCreatedViaOnboardingFlow && isWebSdkIntegrated) ||
    (isWebSdkIntegrated && userInfo?.activeTenantId !== 'supermoney');

  const isDemoWorkspace = useIsDemoWorkspace();
  const isScopeAvailable = useIsScopeAvailable();

  const handleNavigationSidepanelToggle = (open: boolean) => {
    // localStorage.setItem('percept_ns_state', JSON.stringify(open));
    setIsNavigationSidepanelOpen(open);
  };
  return (
    <div
      className={`flex-shrink-0 w-64 border-r-2 border-border transition-width duration-300 z-[20]
        ${isNavigationSidepanelOpen ? 'w-[260px]' : 'w-[60px]'}
        `}
      // style={isNavigationSidepanelOpen ? { width: '260px' } : { width: '60px' }}
    >
      <div
        className={cn(
          `w-full overflow-y-scroll pt-6 px-1 flex flex-col no-scrollbar`,
          `sticky z-[11] bg-background left-0 top-0 h-screen max-h-screen`,
        )}
      >
        <div className="flex-1 overflow-y-scroll pb-12 no-scrollbar px-2">
          <div
            className={cn(
              'border-b border-border pb-2 space-y-2',
              // isNavigationSidepanelOpen ? 'space-y-4 ' : 'space-y-5'
            )}
          >
            <PerceptIcon isNavigationSidepanelOpen={isNavigationSidepanelOpen} />
            <NavbarSearch isNavigationSidepanelOpen={isNavigationSidepanelOpen} />
            <SidepanelNavLink
              isNavigationSidepanelOpen={isNavigationSidepanelOpen}
              path="home"
              text="Home"
              icon={<HomeIcon active={currentPathname.includes('home')} />}
              tooltipContent={
                <SidepanelNavlinkTooltip
                  icon={<HomeIcon active />}
                  description="Product metrics and recently viewed reports at a glance"
                />
              }
            />
            <SidepanelNavLink
              isNavigationSidepanelOpen={isNavigationSidepanelOpen}
              path="all-reports"
              text="All Reports"
              icon={<YourReportsIconV3 active={currentPathname.includes('all-reports')} />}
              tooltipContent={
                <SidepanelNavlinkTooltip
                  icon={<YourReportsIconV3 active />}
                  description="View all available reports "
                />
              }
            />
            <SidepanelNavLink
              isNavigationSidepanelOpen={isNavigationSidepanelOpen}
              path="insights-hub"
              text="Insights Hub"
              icon={
                <LucidIconWrapper
                  name="file-chart-column"
                  stroke={currentPathname.includes('insights-hub') ? CustomSvgIconActiveModeStroke : undefined}
                />
              }
              tooltipContent={
                <SidepanelNavlinkTooltip
                  icon={<LucidIconWrapper name="file-chart-column" stroke={CustomSvgIconActiveModeStroke} />}
                  description="Insights hub"
                />
              }
            />
            <BoardsMenu isNavigationSidepanelOpen={isNavigationSidepanelOpen} currentPathname={currentPathname} />
          </div>
          <div
            className={cn(
              'border-b border-border py-2 space-y-2',
              // isNavigationSidepanelOpen ? 'space-y-4 ' : 'space-y-5'
            )}
          >
            <CreateReportsMenu
              isNavigationSidepanelOpen={isNavigationSidepanelOpen}
              currentPathname={currentPathname}
              toggleSidepanel={setIsNavigationSidepanelOpen}
            />
            <EngageMenu isNavigationSidepanelOpen={isNavigationSidepanelOpen} currentPathname={currentPathname} />
            {canShowExperiments ? (
              <ExperimentsMenu
                isNavigationSidepanelOpen={isNavigationSidepanelOpen}
                currentPathname={currentPathname}
              />
            ) : null}
            {canShowHeatmaps && !isDemoWorkspace ? (
              <SidepanelNavLink
                isNavigationSidepanelOpen={isNavigationSidepanelOpen}
                path="heatmaps/dashboard"
                text="Heatmaps"
                icon={<HeatmapIconV3 active={currentPathname.includes('heatmaps')} />}
                tooltipContent={
                  <SidepanelNavlinkTooltip
                    icon={<HeatmapIconV3 active />}
                    description="Check click frequencies, dead clicks and scroll depth"
                  />
                }
              />
            ) : null}
            {!isDemoWorkspace ? (
              <UserMenu isNavigationSidepanelOpen={isNavigationSidepanelOpen} currentPathname={currentPathname} />
            ) : null}
            <EventsMenu
              isNavigationSidepanelOpen={isNavigationSidepanelOpen}
              currentPathname={currentPathname}
              toggleSidepanel={setIsNavigationSidepanelOpen}
            />
          </div>
          <div
            className={cn(
              'py-2 space-y-2',
              // isNavigationSidepanelOpen ? 'space-y-4 ' : 'space-y-5'
            )}
          >
            {!isDemoWorkspace && (
              <SidepanelNavLink
                isNavigationSidepanelOpen={isNavigationSidepanelOpen}
                path="alert/list"
                text="Alerts"
                icon={
                  <LucidIconWrapper
                    name="bell-ring"
                    stroke={currentPathname.includes('alert-list') ? CustomSvgIconActiveModeStroke : undefined}
                  />
                }
                tooltipContent={
                  <SidepanelNavlinkTooltip
                    icon={<LucidIconWrapper name="bell-ring" stroke={CustomSvgIconActiveModeStroke} />}
                    description="View alerts that were triggered for your reports"
                  />
                }
              />
            )}
            {isScopeAvailable(AccessScopes.WALLET_READ) && !isDemoWorkspace && (
              <WalletMenuNew isNavigationSidepanelOpen={isNavigationSidepanelOpen} currentPathname={currentPathname} />
            )}
            {!isDemoWorkspace && (
              <SidepanelNavLink
                isNavigationSidepanelOpen={isNavigationSidepanelOpen}
                path="sdk-integrations"
                text="Sdk Integrations"
                icon={<SdkIntegrationIconV3 active={currentPathname.includes('sdk-integrations')} />}
                tooltipContent={
                  <SidepanelNavlinkTooltip
                    icon={<SdkIntegrationIconV3 active />}
                    description="View how to integrate our SDKs"
                  />
                }
              />
            )}
            <SettingsMenuNew isNavigationSidepanelOpen={isNavigationSidepanelOpen} />
            <WorkspaceSelectorNew isNavigationSidepanelOpen={isNavigationSidepanelOpen} />
          </div>
        </div>
        <div className={isNavigationSidepanelOpen ? 'text-right mb-2' : 'text-center mb-2'}>
          <Button
            variant="icon"
            onClick={() => handleNavigationSidepanelToggle(!isNavigationSidepanelOpen)}
            className="bg-transparent p-2"
          >
            <LucidIconWrapper name={isNavigationSidepanelOpen ? 'arrow-left-to-line' : 'arrow-right-to-line'} />
          </Button>
        </div>
      </div>
    </div>
  );
}
