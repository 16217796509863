import { DropdownMenuSeparator } from '@src/client/ui-library/dropdown';
import { ExperimentIconV3, FeatureFlagIconV3 } from '@src/client/ui-library/icons/v3';

import NestedNavigationMenu from './NestedNavigationMenu';
import SidepanelNavLink from './SidepanelNavLink';
import SidepanelNavlinkTooltip from './SidepanelNavlinkTooltip';

export default function ExperimentsMenu({
  isNavigationSidepanelOpen,
  currentPathname,
}: {
  isNavigationSidepanelOpen: boolean;
  currentPathname: string;
}) {
  const isABExperimentOpen = currentPathname.includes('experiment');
  const isFeatureFlagOpen = currentPathname.includes('feature-flag');
  const isExperimentsOpen = isABExperimentOpen || isFeatureFlagOpen;

  return (
    <NestedNavigationMenu
      isNavigationSidepanelOpen={isNavigationSidepanelOpen}
      triggerText="Experiments"
      isActive={isExperimentsOpen}
      triggerIcon={<ExperimentIconV3 active={isExperimentsOpen} />}
      tooltipContent={
        <SidepanelNavlinkTooltip
          icon={<ExperimentIconV3 active />}
          description="Compare variations, Control feature releases"
        />
      }
    >
      <SidepanelNavLink
        isNavigationSidepanelOpen
        path="experiment/list"
        text="Experiment"
        icon={<ExperimentIconV3 active={isABExperimentOpen} />}
        tooltipContent={
          <SidepanelNavlinkTooltip
            icon={<ExperimentIconV3 active />}
            description="Test and compare product/feature variations to identify the best performer."
          />
        }
      />
      <DropdownMenuSeparator className="px-2 my-2" />
      <SidepanelNavLink
        isNavigationSidepanelOpen
        path="feature-flag/list"
        text="Feature Flags"
        icon={<FeatureFlagIconV3 active={isFeatureFlagOpen} />}
        tooltipContent={
          <SidepanelNavlinkTooltip
            icon={<FeatureFlagIconV3 active />}
            description="Roll out new features to specific user groups for controlled testing."
          />
        }
      />
    </NestedNavigationMenu>
  );
}
