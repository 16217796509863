import { ValueType } from '@src/client/helpers/reports/constants';
import { isLengthyArray } from '@src/client/lib/utils';
import * as d3 from 'd3';
import { throttle, uniq, uniqBy } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';

import LineChartTooltip from '../line-chart/LineChartTooltip';
import LinePath from '../line-chart/LinePath';
import { D3LineData, TooltipInfo } from '../line-chart/types';
import AreaBuilder from './AreaBuilder';

interface Props {
  lineData: D3LineData[];
  width: number;
  height: number;
  hideTooltip?: boolean;
}

export default function AreaChartSvg({ lineData, width, height, hideTooltip }: Props) {
  const boundsWidth = useMemo(() => width - 0, [width]);
  const boundsHeight = useMemo(() => height - 0, [height]);
  const groupedLineData = useMemo(() => d3.groups(lineData, (d) => d.key), [lineData]);
  const uniqueLineKeys = uniq(lineData.map((l) => l.key));
  const [tooltipInfo, setTolltipInfo] = useState<TooltipInfo | undefined>();

  const uniqueDateEntries = useMemo(
    () => uniqBy(lineData, (d) => d.date),
    [lineData], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const xScale = d3
    .scaleTime()
    .domain([uniqueDateEntries[0].date, uniqueDateEntries[uniqueDateEntries.length - 1].date])
    .range([0, boundsWidth]);
  // .nice();

  const yScale = d3
    .scaleLinear()
    .domain(d3.extent(lineData.map((d) => d.value)) as [number, number])
    .range([height - 0, 0]);
  // .nice();

  const points = useMemo(
    () => lineData.map((d) => [xScale(d.date), yScale(d.value), d.series]),
    [xScale, yScale, lineData],
  );

  const handleTouchStart = useCallback((e: React.TouchEvent<SVGSVGElement>) => e.preventDefault(), []);

  const handlePointerEnter = useCallback(() => {}, []);

  const handlePointerLeave = useCallback(() => {
    setTolltipInfo(undefined);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePointerMove = useCallback(
    (event: React.PointerEvent<SVGSVGElement>) => {
      if (!isLengthyArray(points)) return;
      const [xm, ym] = d3.pointer(event);
      const i = d3.leastIndex(points, ([x, y]) => Math.hypot(Number(x) - xm, Number(y) - ym));
      if (i === undefined || !points[i!]) return;
      const [x, y, k] = points[i!];
      setTolltipInfo({ x, y, k, i });
    },
    [points], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const throttledPointerMove = throttle(handlePointerMove, 200);

  return (
    <svg
      className=""
      viewBox={`0 0 ${width} ${height}`}
      onPointerEnter={handlePointerEnter}
      onPointerMove={throttledPointerMove}
      onPointerLeave={handlePointerLeave}
      onTouchStart={handleTouchStart}
    >
      {groupedLineData.map((d) => (
        <g key={d[0]}>
          <AreaBuilder lineData={d[1]!} xScale={xScale} yScale={yScale} />
          <LinePath lineData={d[1]!} xScale={xScale} yScale={yScale} isTinyChart />
        </g>
      ))}
      {hideTooltip ? null : (
        <LineChartTooltip
          allDataPoints={lineData}
          tooltipInfo={tooltipInfo}
          valueType={ValueType.NUMBER}
          chartWidth={boundsWidth}
          chartHeight={boundsHeight}
        />
      )}
    </svg>
  );
}
