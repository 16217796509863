import { EventNames } from '@src/client/lib/analytics/events';
import Tracker from '@src/client/lib/analytics/tracker';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { useTheme } from '.';

type UIVersion = 'v2' | 'v3';

type UIVersionProviderProps = {
  children: React.ReactNode;
  defaultUI?: UIVersion;
  storageKey?: string;
};

type UIVersionState = {
  uiVersion: UIVersion;
  setUIVersion: (uiVersion: UIVersion) => void;
};

const initialState: UIVersionState = {
  uiVersion: 'v3',
  setUIVersion: () => null,
};

const UIVersionContext = createContext<UIVersionState>(initialState);

export const UIStoragKey = 'pi-ui-version';

// const setLightThemeVariablesForV2 = () => {
//   document.documentElement.style.setProperty('--foreground', '12 17 29');
//   document.documentElement.style.setProperty('--foreground-secondary', '12 17 29');
//   document.documentElement.style.setProperty('--foreground-light', '12 17 29');

//   document.documentElement.style.setProperty('--background', '255 255 255');
// };

// const setDarkThemeVariablesForV2 = () => {
//   document.documentElement.style.setProperty('--foreground', '255 255 255');
//   document.documentElement.style.setProperty('--foreground-secondary', '75 85 101');
//   document.documentElement.style.setProperty('--foreground-light', '205 213 223');

//   document.documentElement.style.setProperty('--background', '32 41 57');
// };

export function UIVersionProvider({
  children,
  defaultUI = 'v3',
  storageKey = UIStoragKey,
  ...props
}: UIVersionProviderProps) {
  const [activeUIVersion, setActiveUIVersion] = useState<UIVersion>(() => {
    const lastPopupTS = localStorage.getItem('pi_last_ui_version_alert_ts');
    if (!lastPopupTS) return 'v3';
    return (localStorage.getItem(storageKey) as UIVersion) || defaultUI;
  });
  const { theme } = useTheme();

  useEffect(() => {
    // if (activeUIVersion === 'v2') {
    //   if (theme === 'light') setLightThemeVariablesForV2();
    //   if (theme === 'dark') setDarkThemeVariablesForV2();
    // }
  }, []);

  const value = useMemo(
    () => ({
      uiVersion: activeUIVersion,
      setUIVersion: (uiVersion: UIVersion) => {
        localStorage.setItem(storageKey, uiVersion);
        // setActiveUIVersion(uiVersion);
        Tracker.trackEvent(uiVersion === 'v3' ? EventNames.EnableUIV3 : EventNames.DisableUIV3);
        window.location.reload();
      },
    }),
    [activeUIVersion, storageKey],
  );

  return (
    <UIVersionContext.Provider {...props} value={value}>
      {children}
    </UIVersionContext.Provider>
  );
}

export const useUIVersion = () => {
  const context = useContext(UIVersionContext);

  if (context === undefined) throw new Error('useUIVersion must be used within a UiVersionProvider');

  return context;
};
