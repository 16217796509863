import { isPastCompareString, replacePastFlagFromCompareString } from '@src/client/helpers/reports/dataUtils';
import { useTheme } from '@src/client/ui-library/theme-provider';
import * as d3 from 'd3';
import { motion } from 'framer-motion';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { isDateCompareEnabledSelector } from '../../../filters-and-selectors/compare-selector/atoms';
import { D3LineData } from './types';

interface Props {
  lineData: D3LineData[];
  activeLine?: string;
  xScale: d3.ScaleTime<number, number, never> | d3.ScaleLinear<number, number, never>;
  yScale: d3.ScaleLinear<number, number, never>;
  showDots?: boolean;
  isTinyChart?: boolean;
}

// const getPathAnimatedValues = (path: string) => {
//   let computedValues = '';
//   path.split(',').reduce((accm, item, index) => {
//     if (index === 0) {
//       accm = `${item}`;
//       computedValues = `${accm}`;
//     } else {
//       accm = `${accm},${item}`;
//       computedValues = `${computedValues};${accm}`;
//     }

//     return accm;
//   }, '');

//   return computedValues;
// };

const activeLineMatcher = (lineKey: string, activeLinekey: string) =>
  replacePastFlagFromCompareString(lineKey) === replacePastFlagFromCompareString(activeLinekey);

export default function LinePath({ isTinyChart, lineData, activeLine, xScale, yScale, showDots }: Props) {
  const isComapreEnabled = useRecoilValue(isDateCompareEnabledSelector);
  const { theme } = useTheme();

  const lineBuilder = useMemo(
    () =>
      d3
        .line<D3LineData>()
        .x((d) => xScale(isComapreEnabled ? d.compareDate! : d.date))
        .y((d) => yScale(d.value)),
    // .curve(d3.curveNatural), // d3.curveCatmullRom.alpha(0.5)
    [isComapreEnabled, xScale, yScale],
  );

  const linePath = useMemo(() => lineBuilder(lineData), [lineBuilder, lineData]);

  if (!linePath) {
    return null;
  }

  return (
    <g>
      <motion.path
        initial={{ pathLength: 0 }}
        animate={{ pathLength: 1 }}
        transition={{
          pathLength: { delay: 0, type: 'tween', duration: 2, bounce: 0 },
        }}
        d={linePath}
        fill="none"
        opacity={activeLine ? (activeLineMatcher(lineData[0].key, activeLine) ? 1 : 0.4) : 1}
        stroke={`#${lineData[0].color}`}
        strokeWidth={activeLine ? (activeLineMatcher(lineData[0].key, activeLine) ? 4 : 0.5) : 2}
        id={lineData[0].key}
      />
      {/* Adding extra line here for dashed line animation as framer motion does not support it yet */}
      {isPastCompareString(lineData[0].key) ? (
        <path
          stroke={theme === 'light' ? '#fff' : '#202939'}
          strokeDasharray="5px"
          strokeDashoffset="0"
          strokeWidth={activeLine ? (activeLineMatcher(lineData[0].key, activeLine) ? 4 : 0.5) : 2}
          style={{ fill: 'none', fillRule: 'evenodd', strokeLinejoin: 'round' }}
          d={linePath}
        />
      ) : null}

      {/* Commented code for custom animation without framer motion. this one also has challeneges with dashed line. */}
      {/* <path
        d={linePath}
        fill="none"
        opacity={activeLine ? (activeLine === lineData[0].key ? 1 : 0.4) : 1}
        stroke={`#${lineData[0].color}`}
        strokeWidth={activeLine ? (activeLineMatcher(lineData[0].key, activeLine) ? 4 : 0.5) : 2}
        strokeDasharray={isPastCompareString(lineData[0].key)? '5px' : ''}
        // className={isPastCompareString(lineData[0].key) ? '' : 'animateLinePath'}
        id={lineData[0].key}
      >
        <animate attributeName="d" dur="1s" repeatCount={1} values={getPathAnimatedValues(linePath)} fill="freeze" />
      </path> */}
      {showDots || lineData.length === 1
        ? lineData.map((d, i) =>
            d.date && d.value ? (
              <circle
                key={d.date}
                r="3"
                cx={xScale(d.date)}
                cy={yScale(d.value)}
                fill={`#${lineData[0].color}`}
                strokeWidth={1}
                stroke={theme === 'light' ? '#fff' : '#202939'}
                opacity={activeLine ? (activeLineMatcher(d.key, activeLine) ? 1 : 0.4) : 1}
              />
            ) : null,
          )
        : null}
    </g>
  );
}
