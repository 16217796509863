/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback } from 'react';
import { components as ReactSelectComponents, MultiValueProps, NoticeProps, OptionProps } from 'react-select';
import ReactAsyncCreatableSelect from 'react-select/async-creatable';

import { SelectCheckIcon } from '../icons/ReportIcons';
import { Tooltip } from '../tooltip';
import { cn, HoverableClassNames } from '../utils';
import { SelectClassNames, SelectOptionsType } from './types';

const highlightInputMatch = (item: string | undefined, keyword: string) => {
  if (!item || !keyword || typeof item !== 'string') return item;
  const lowerCasedInputValue = keyword.toLowerCase();
  const hitIndex = item.toLocaleLowerCase().indexOf(lowerCasedInputValue);
  if (hitIndex === -1) return item;
  const before = item.slice(0, hitIndex);
  const match = item.slice(hitIndex, hitIndex + keyword.length);
  const after = item.slice(hitIndex + keyword.length);
  return (
    <span>
      {before}
      <b>{match}</b>
      {after}
    </span>
  );
};

function NoOptionsMessage({ ...props }: NoticeProps) {
  return (
    <p className="text-xs text-center">
      {props.selectProps.inputValue.length <= 1 ? 'Start typing to view options' : 'No options found'}
    </p>
  );
}

function CustomOption({ innerProps, data, isSelected, ...props }: OptionProps) {
  // eslint-disable-next-line no-unused-vars
  const { onMouseMove, onMouseOver, ...rest } = innerProps; // check: https://www.botsplash.com/post/optimize-your-react-select-component-to-smoothly-render-10k-data
  const { inputValue } = props.selectProps;
  return (
    <div
      {...rest}
      className={cn(
        'cursor-default select-none flex w-full justify-between items-center rounded py-1.5 px-2 mb-1  bg-transparent hover:bg-gray-100 hover:dark:bg-foreground-secondary focus:bg-gray-100 focus:dark:bg-foreground-secondary',
        isSelected ? 'bg-foreground-secondary' : '',
      )}
    >
      <div className="inline-flex items-center space-x-1 w-full">
        {(data as any).icon ? (data as any).icon : null}
        <Tooltip content={(data as any).label}>
          <span className="truncate text-sm outline-none text-foreground">
            {highlightInputMatch((data as any).label, inputValue)}
          </span>
        </Tooltip>
      </div>
      {isSelected ? <SelectCheckIcon /> : null}
    </div>
  );
}

function CustomMultiValue({ index, getValue, data, ...props }: MultiValueProps) {
  const maxToShow = 5;
  const values = getValue();
  const overflowCount = Math.max(values.length - maxToShow, 0);

  if (index < maxToShow) {
    return <ReactSelectComponents.MultiValue index={index} data={data} getValue={getValue} {...props} />;
  }
  if (index === maxToShow) {
    const overflowValues = values
      .slice(maxToShow)
      .map((value) => (value as SelectOptionsType).label)
      .join(', ');

    return (
      <Tooltip
        content={<p className="text-foreground">{overflowValues}</p>}
        side="bottom"
        contentClassname="bg-background border border max-h-[180px] max-w-[520px] overflow-y-scroll"
      >
        <span>
          <ReactSelectComponents.MultiValue
            index={index}
            getValue={getValue}
            data={{ isFixed: true }}
            {...props}
          >{`+${overflowCount} more`}</ReactSelectComponents.MultiValue>
        </span>
      </Tooltip>
    );
  }
  return null;
}

const AsyncCreatableSelect = React.forwardRef<
  React.ElementRef<typeof ReactAsyncCreatableSelect>,
  React.ComponentPropsWithoutRef<typeof ReactAsyncCreatableSelect> & { selectClassNames?: SelectClassNames }
>(
  (
    {
      cacheOptions,
      defaultOptions,
      selectClassNames,
      loadOptions,
      isMulti,
      value,
      isLoading,
      onChange,
      menuIsOpen,
      placeholder,
      components = {},
      ...props
    },
    ref,
  ) => {
    const formatCreateLabel = useCallback(
      (inputValue: string) => <p className="text-sm font-semibold text-left">+ Add &quot;{inputValue}&quot;</p>,
      [],
    );

    return (
      <ReactAsyncCreatableSelect
        ref={ref}
        cacheOptions={cacheOptions}
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        hideSelectedOptions={false}
        value={value}
        isLoading={isLoading}
        onChange={onChange}
        unstyled
        menuIsOpen={menuIsOpen}
        placeholder={placeholder}
        components={{ NoOptionsMessage, Option: CustomOption, MultiValue: CustomMultiValue, ...components }}
        styles={{
          // Fixes the overlapping problem of the component
          menu: (provided) => ({ ...provided, zIndex: 20 }),
        }}
        onCreateOption={
          // Define onCreateOption only if it is multi and onChange is provided.
          // This will act as a substitute method to the "+Add" option and will be capable of adding comma-separated values.
          isMulti && onChange
            ? (val) => {
                const newValues = val
                  .split(',')
                  .filter((item) => item.trim() !== '')
                  .filter((item, index, array) => array.indexOf(item) === index)
                  .map((item) => ({ value: item.trim(), label: item.trim() }));
                const newOptions = [
                  ...(value as SelectOptionsType[]),
                  ...newValues.filter(
                    (newValue) =>
                      (value as SelectOptionsType[]).findIndex(
                        (existingValue) => existingValue.value === newValue.value,
                      ) === -1,
                  ),
                ].slice(0, 500);
                // https://stackoverflow.com/questions/68272060/property-option-does-not-exist-on-type-createoptionactionmeta
                // That could be just a wrong type definitions for create-option action meta. But while CreateOptionActionMeta
                // type does not have an option field it's runtime value has it. And also it is accessible as the last element
                // of selectedValues argument.
                onChange(newOptions, { action: 'create-option', name: 'select', option: newOptions.slice(-1) });
              }
            : undefined
        }
        formatCreateLabel={formatCreateLabel}
        createOptionPosition="first"
        classNames={{
          control: (_state) =>
            cn('border border-border rounded-xl px-2 py-1', HoverableClassNames, selectClassNames?.control),
          menu: (_state) =>
            cn(
              'border border-border  bg-popover text-popover-foreground shadow-md p-2 mt-2 rounded',
              selectClassNames?.menu,
            ),
          option: (state) =>
            cn(
              'cursor-default truncate select-none items-center rounded py-1.5 px-2 mb-1 text-sm outline-none bg-transparent hover:bg-primary-light hover:dark:bg-foreground-secondary focus:bg-primary-light focus:dark:bg-foreground-secondary',
              state.isSelected ? 'bg-primary-light dark:bg-foreground-secondary' : '',
            ),
          singleValue: (_state) => cn('text-sm font-normal', selectClassNames?.singleValue),
          multiValue: (_state) =>
            'bg-white border border-border dark:border-0 rounded-xl px-2 py-0.5 select-none my-0.5',
          multiValueLabel: (_state) => 'text-xs text-black font-normal bg-transparent',
          multiValueRemove: (_state) => 'text-black',
          placeholder: (_state) =>
            cn('text-sm font-normal text-foreground-secondary dark:text-foreground', selectClassNames?.placeholder),
          indicatorsContainer: (_state) => cn('opacity-70', selectClassNames?.indicatorsContainer),
          dropdownIndicator: (_state) => cn('', selectClassNames?.dropdownIndicator),
          loadingMessage: (_state) => 'text-sm font-semibold text-foreground-secondary text-left',
          container: (_state) => cn('', selectClassNames?.container),
        }}
        {...props}
      />
    );
  },
);

AsyncCreatableSelect.displayName = 'AsyncCreatableSelect';

export default AsyncCreatableSelect;
