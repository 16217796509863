import { DropdownMenuLabel, DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu';
import { googleLogout } from '@react-oauth/google';
import { useIsOnboardingCreatedWorkspace } from '@src/client/helpers/reports/hooks';
import { useUserInfoQuery } from '@src/client/hooks';
import { EventNames } from '@src/client/lib/analytics/events';
import Tracker from '@src/client/lib/analytics/tracker';
import { Cookie } from '@src/client/lib/api/constants';
import { updateLoginTenant } from '@src/client/lib/api/queries/common';
import { OauthProvider } from '@src/client/lib/api/types/request';
import { msalLogout } from '@src/client/lib/msal-utils';
import { clearActiveWorkspaceDetailsFromLocalStorage } from '@src/client/lib/utils';
import { userInfoState } from '@src/client/recoil/atoms';
import { DropdownMenu, DropdownMenuContent, DropdownMenuSeparator } from '@src/client/ui-library/dropdown';
import { cn } from '@src/client/ui-library/utils';
import { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useMutation, useQueryClient } from 'react-query';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { SettingsIconV3 } from '../../ui-library/icons/v3';
import { globalPageLoaderState } from '../global-page-loader';
import LucidIconWrapper from '../lucid-icon-wrapper';
import { ELEVATED_ACCESS_ROLES } from '../site-navbar/constants';
import ThemeToggler from '../site-navbar/ThemeToggler';
import UIVersionToggler from '../site-navbar/UIVersionToggler';
import { SidepanelActiveNavClassNames, SidepanelNavLinkClassNames } from './constants';
import SidepanelNavLink from './SidepanelNavLink';

const USE_OLD_AUTH_SERVICE = true;

const elevatedAccessItems = [
  {
    label: 'Users and Teams',
    link: 'settings/manage-users',
    eventName: EventNames.MANAGE_USERS_CLICKED,
    icon: <LucidIconWrapper name="users" />,
  },
  {
    label: 'Data Governance',
    link: 'settings/data-governance',
    eventName: EventNames.DATA_GOVERNANCE_CLICKED,
    icon: <LucidIconWrapper name="shield-ellipsis" />,
  },
  {
    label: 'Engage Settings',
    link: 'engage-settings',
    eventName: EventNames.ENGAGE_SETTINGS_CLICKED,
    icon: <LucidIconWrapper name="captions" />,
  },
  {
    label: 'Workspace Settings',
    link: 'workspace-settings',
    eventName: EventNames.WORKSPACE_SETTINGS_CLICKED,
    icon: <LucidIconWrapper name="briefcase" />,
  },
  // {
  //   label: 'SDK Settings',
  //   link: 'sdk-configs',
  //   eventName: EventNames.SDK_CONFIGS_CLICKED,
  //   icon: <LucidIconWrapper name="settings-2" />,
  // },
];

export default function SettingsMenuNew({ isNavigationSidepanelOpen }: { isNavigationSidepanelOpen: boolean }) {
  const [tenantId] = useState('');
  const [userData, setUserData] = useRecoilState(userInfoState);
  const [cookies, , removeCookie] = useCookies([
    Cookie.id_token,
    Cookie.refresh_token,
    Cookie.auth_type,
    Cookie.id_token_expiry,
  ]);
  const queryClient = useQueryClient();
  const [darkMode] = useState(false);
  const [userInfo, setuserInfo] = useRecoilState(userInfoState);
  const setShowGlobalPageLoader = useSetRecoilState(globalPageLoaderState);
  const isElevatedAccess = userData && ELEVATED_ACCESS_ROLES.includes(userData.roleName);
  const { mutate: updateTenantMutation } = useMutation(updateLoginTenant);

  const { status } = useUserInfoQuery(sessionStorage.getItem('first_time_user') !== 'true' && !userInfo);

  const isWsCreatedViaOnboardlingFlow = useIsOnboardingCreatedWorkspace();
  const isSettingsSubPageOpen = ['settings/manage-users', 'settings/data-governance', 'engage-settings'].some((p) =>
    window.location.pathname.includes(p),
  );

  useEffect(() => {
    if (userInfo) {
      setUserData(userInfo);
      Tracker.setUserInfo(userInfo);
    }
  }, [userInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSignOut = useCallback(async () => {
    setShowGlobalPageLoader(true);
    if (!USE_OLD_AUTH_SERVICE) {
      updateTenantMutation(tenantId);
    }
    Tracker.logout();
    clearActiveWorkspaceDetailsFromLocalStorage();
    removeCookie(Cookie.id_token, {
      path: '/',
    });
    removeCookie(Cookie.refresh_token, {
      path: '/',
    });
    removeCookie(Cookie.id_token_expiry, {
      path: '/',
    });
    removeCookie(Cookie.auth_type, {
      path: '/',
    });
    await queryClient.invalidateQueries();
    if (cookies[Cookie.auth_type] === OauthProvider.google) {
      googleLogout();
    }
    if (cookies[Cookie.auth_type] === OauthProvider.microsoft) {
      await msalLogout();
    }
    setShowGlobalPageLoader(false);
    window.window.location.href = '/login';
  }, [tenantId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (status === 'error') {
      onSignOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove('light', 'dark');
    const theme = darkMode ? 'dark' : 'light';
    root.classList.add(theme);
  }, [darkMode]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className={cn(
          `w-full bg-transparent`,
          SidepanelNavLinkClassNames,
          isNavigationSidepanelOpen ? 'p-2' : 'px-1 py-2 justify-center',
          isSettingsSubPageOpen ? SidepanelActiveNavClassNames : '',
        )}
      >
        {isNavigationSidepanelOpen ? (
          <div className="flex items-center justify-between w-full">
            <div className="inline-flex items-center space-x-2">
              <SettingsIconV3 active={isSettingsSubPageOpen} />
              <p className="text-sm font-medium">Settings</p>
            </div>
            <LucidIconWrapper name="chevron-right" />
          </div>
        ) : (
          <SettingsIconV3 active={isSettingsSubPageOpen} />
        )}
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[260px] ml-2 p-2 shadow-none" side="right">
        {isElevatedAccess && (
          <section>
            {elevatedAccessItems.map(({ label, link, icon }) => (
              <div key={link}>
                <SidepanelNavLink isNavigationSidepanelOpen path={link} text={label} icon={icon} />
                <DropdownMenuSeparator className="px-2 my-2" />
              </div>
            ))}
          </section>
        )}
        <DropdownMenuLabel className="flex items-center justify-between mb-2 p-2">
          <p className="text-sm font-medium text-foreground-selected">Dark Mode</p>
          <ThemeToggler />
        </DropdownMenuLabel>
        {isWsCreatedViaOnboardlingFlow ? null : (
          <DropdownMenuLabel className="flex items-center justify-between mb-2 p-2">
            <p className="text-sm font-medium text-foreground-selected">New UI</p>
            <UIVersionToggler />
          </DropdownMenuLabel>
        )}
        <DropdownMenuLabel
          onClick={onSignOut}
          className="flex items-center justify-between p-2 cursor-pointer rounded-md bg-transparent hover:bg-gray-50 dark:hover:bg-foreground-secondary"
        >
          <p className="text-sm font-medium text-foreground-selected">Logout</p>
          <LucidIconWrapper name="log-out" />
        </DropdownMenuLabel>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
