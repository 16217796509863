/* eslint-disable react/require-default-props */
import SiteNavbar from '@src/client/components/site-navbar';
import { useIsOnboardingCreatedWorkspace } from '@src/client/helpers/reports/hooks';
import { EventNames } from '@src/client/lib/analytics/events';
import Tracker from '@src/client/lib/analytics/tracker';
import { ScreenNames } from '@src/client/routes/data';
import { Button } from '@src/client/ui-library/button';
import { Dialog } from '@src/client/ui-library/dialog';
import { ThemeProvider, ThemeStorageKey } from '@src/client/ui-library/theme-provider';
import { useUIVersion } from '@src/client/ui-library/theme-provider/uiProvider';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Toaster } from '../../ui-library/toast/toaster';
import NavigationSidepanel from '../navigation-sidepanel';

interface LayoutProps extends PropsWithChildren {
  screenName: ScreenNames;
  hideSidePanel?: boolean;
  contentStyle?: React.CSSProperties;
}

function ShowNewUiAlertBox() {
  const [showAlert, setShowAlert] = useState(false);
  const { uiVersion, setUIVersion } = useUIVersion();
  const isOnboardingFlowWs = useIsOnboardingCreatedWorkspace();

  const showNewUiAlertBox = () => {
    setShowAlert(true);
    localStorage.setItem('pi_last_ui_version_alert_ts', Date.now().toString());
  };

  useEffect(() => {
    const lastShown = localStorage.getItem('pi_last_ui_version_alert_ts');
    // if (uiVersion === 'v2' && (!lastShown || Date.now() - Number(lastShown) > 7 * 24 * 60 * 60 * 100))
    if (uiVersion === 'v3' && !lastShown && !isOnboardingFlowWs) {
      Tracker.trackEvent(EventNames.UIv3DialogShown);
      showNewUiAlertBox();
    }
  }, [uiVersion, isOnboardingFlowWs]);

  const closeAlert = () => {
    setShowAlert(false);
    Tracker.trackEvent(EventNames.DisableUIV3);
  };

  const toggleAlert = (open: boolean) => {
    if (!open) {
      closeAlert();
    }
  };

  return (
    <Dialog open={showAlert} onOpenChange={toggleAlert}>
      <div className="w-[420px] m-auto">
        <div className="flex flex-col text-center mb-10">
          <img
            src="/images/ui-update-icon.svg"
            style={{ width: 128, height: 128 }}
            className="mb-4 mx-auto"
            alt="Percept version upgrade icon"
          />
          <h1 className="font-bold text-foreground-selected text-h4 mb-4">PerceptInsight v2.0</h1>
          <p className="font-medium text-h6 mb-4">
            We have upgraded our experience, with all new layout, navigation, insight hub and more
          </p>
        </div>
        <div className="flex flex-col space-y-4">
          <Button variant="primary" onClick={closeAlert}>
            Okay
          </Button>
          <p className="text-xxs text-center">
            You can switch back to old experience by toggling the New UI button in settings
          </p>
        </div>
      </div>
    </Dialog>
  );
}

export default function Layout({ children, screenName, contentStyle = {}, hideSidePanel = false }: LayoutProps) {
  const location = useLocation();
  const { uiVersion } = useUIVersion();

  useEffect(() => {
    Tracker.setCurrentScreenName(screenName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location && location.pathname !== '/') {
      Tracker.trackEvent(
        EventNames.PAGE_VIEW,
        {},
        {
          pathName: location.pathname,
        },
      );
    }
  }, [location]);

  return (
    <ThemeProvider defaultTheme="light" storageKey={ThemeStorageKey}>
      {uiVersion === 'v2' ? <SiteNavbar /> : null}
      <main
        className={`bg-background text-foreground ${
          uiVersion === 'v2' ? 'min-h-layout-v2 pt-[60px]' : 'min-h-screen flex'
        } box-content ${hideSidePanel ? 'pt-24' : ''}`}
        style={{ ...contentStyle }}
      >
        {uiVersion === 'v3' && !hideSidePanel ? <NavigationSidepanel /> : null}
        {uiVersion === 'v3' ? <div className="flex-grow w-full">{children}</div> : children}
      </main>
      <Toaster />
      <ShowNewUiAlertBox />
    </ThemeProvider>
  );
}
