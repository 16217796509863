import { isDateCompareEnabledSelector } from '@src/client/components/filters-and-selectors/compare-selector/atoms';
import { granularityState } from '@src/client/components/filters-and-selectors/granularity-selector';
import { GranularityEnum, ValueType } from '@src/client/helpers/reports/constants';
import { isLengthyArray } from '@src/client/lib/utils';
import { tableHoverRowState } from '@src/client/ui-library/table';
import * as d3 from 'd3';
import { throttle, uniq, uniqBy } from 'lodash-es';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import LineChartTooltip from './LineChartTooltip';
import LinePath from './LinePath';
import { D3LineData, TooltipInfo } from './types';
import { DEFAULT_MARGIN } from './utils';
import XAxis from './XAxis';
import YAxis from './YAxis';

export default function LineChartSvg({
  lineData,
  width,
  height,
  valueType,
  logarithmicScale,
  customXAxis,
  hideYAxis,
  hideXAxis,
  isTinyChart = false,
}: {
  lineData: D3LineData[];
  width: number;
  height: number;
  valueType?: ValueType;
  logarithmicScale?: boolean;
  hideYAxis: boolean;
  hideXAxis: boolean;
  isTinyChart?: boolean;
  customXAxis?: (
    granularity: GranularityEnum,
    height: number,
    ticks: {
      value: number;
      xOffset: number;
    }[],
  ) => ReactNode;
}) {
  const boundsWidth = useMemo(
    () => (isTinyChart ? width : width - DEFAULT_MARGIN.right - DEFAULT_MARGIN.left),
    [width, isTinyChart],
  );
  const boundsHeight = useMemo(
    () => (isTinyChart ? height : height - DEFAULT_MARGIN.top - DEFAULT_MARGIN.bottom),
    [height, isTinyChart],
  );
  const groupedLineData = useMemo(() => d3.groups(lineData, (d) => d.key), [lineData]);
  const [tooltipInfo, setTolltipInfo] = useState<TooltipInfo | undefined>();
  const isCompareEnabled = useRecoilValue(isDateCompareEnabledSelector);
  const granularity = useRecoilValue(granularityState);
  const hoveredTableRowKey = useRecoilValue(tableHoverRowState);
  const uniqueLineKeys = uniq(lineData.map((l) => l.key));

  const uniqueDateEntries = useMemo(
    () => (isCompareEnabled ? uniqBy(lineData, (d) => d.compareDate!) : uniqBy(lineData, (d) => d.date)),
    [isCompareEnabled, lineData], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const xScale = customXAxis
    ? d3.scaleLinear().domain([0, lineData.length]).range([80, boundsWidth])
    : d3
        .scaleTime()
        .domain(
          isCompareEnabled
            ? [uniqueDateEntries[0].compareDate!, uniqueDateEntries[uniqueDateEntries.length - 1].compareDate!]
            : [uniqueDateEntries[0].date, uniqueDateEntries[uniqueDateEntries.length - 1].date],
        )
        .range([isTinyChart ? 0 : DEFAULT_MARGIN.left, boundsWidth])
        .nice();

  const yScale = logarithmicScale
    ? d3
        .scaleLog()
        .domain(d3.extent(lineData.map((d) => d.value)) as [number, number])
        .range([height - DEFAULT_MARGIN.bottom, DEFAULT_MARGIN.top])
        .nice()
    : d3
        .scaleLinear()
        .domain(d3.extent(lineData.map((d) => d.value)) as [number, number])
        .range([height - DEFAULT_MARGIN.bottom, DEFAULT_MARGIN.top])
        .nice();

  const points = useMemo(
    () => lineData.map((d) => [xScale(isCompareEnabled ? d.compareDate! : d.date), yScale(d.value), d.series]),
    [xScale, yScale, lineData, isCompareEnabled],
  );

  const handleTouchStart = useCallback((e: React.TouchEvent<SVGSVGElement>) => e.preventDefault(), []);

  const handlePointerEnter = useCallback(() => {}, []);

  const handlePointerLeave = useCallback(() => {
    setTolltipInfo(undefined);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePointerMove = useCallback(
    (event: React.PointerEvent<SVGSVGElement>) => {
      if (!isLengthyArray(points)) return;
      const [xm, ym] = d3.pointer(event);
      const i = d3.leastIndex(points, ([x, y]) => Math.hypot(Number(x) - xm, Number(y) - ym));
      if (i === undefined || !points[i!]) return;
      const [x, y, k] = points[i!];
      setTolltipInfo({ x, y, k, i });
    },
    [points], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const throttledPointerMove = throttle(handlePointerMove, 200);

  const ticks = useMemo(() => {
    const { ticks: generateTicks } = xScale;
    return generateTicks(Math.min(uniqueDateEntries.length, 8)).map((value) => ({
      value: Number(value),
      xOffset: xScale(value),
    }));
  }, [xScale, uniqueDateEntries.length]);

  const getXAxis = () =>
    customXAxis ? (
      customXAxis(granularity, height, ticks)
    ) : (
      <XAxis
        xScale={xScale as d3.ScaleTime<number, number, never>}
        height={height}
        ticksCount={uniqueDateEntries.length}
      />
    );

  return (
    <svg
      className=""
      viewBox={`0 0 ${width} ${height}`}
      onPointerEnter={handlePointerEnter}
      onPointerMove={throttledPointerMove}
      onPointerLeave={handlePointerLeave}
      onTouchStart={handleTouchStart}
    >
      {!hideXAxis && getXAxis()}
      {!hideYAxis && <YAxis yScale={yScale} width={width} />}
      {groupedLineData.map((d) => (
        <LinePath
          lineData={d[1]!}
          xScale={xScale}
          yScale={yScale}
          key={d[0]}
          activeLine={
            (tooltipInfo?.k as string) ??
            (hoveredTableRowKey && uniqueLineKeys.indexOf(hoveredTableRowKey) > -1 ? hoveredTableRowKey : null)
          }
          showDots={
            isTinyChart ? false : granularity !== GranularityEnum.HOUR && granularity !== GranularityEnum.MINUTE
          }
          isTinyChart={isTinyChart}
        />
      ))}
      <LineChartTooltip
        allDataPoints={lineData}
        tooltipInfo={tooltipInfo}
        valueType={valueType}
        chartWidth={boundsWidth}
        chartHeight={boundsHeight}
        notFormatDate={!!customXAxis}
      />
    </svg>
  );
}
