import { DropdownMenuSeparator } from '@src/client/ui-library/dropdown';
import { EventsMenuIconV3 } from '@src/client/ui-library/icons/v3';
import { CustomSvgIconActiveModeStroke } from '@src/client/ui-library/icons/v3/constants';
import { useEffect } from 'react';

import LucidIconWrapper from '../lucid-icon-wrapper';
import NestedNavigationMenu from './NestedNavigationMenu';
import SidepanelNavLink from './SidepanelNavLink';
import SidepanelNavlinkTooltip from './SidepanelNavlinkTooltip';

export default function EventsMenu({
  isNavigationSidepanelOpen,
  currentPathname,
  toggleSidepanel,
}: {
  isNavigationSidepanelOpen: boolean;
  currentPathname: string;
  toggleSidepanel: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const isLiveEventsPageOpen = currentPathname.includes('events') && !currentPathname.includes('custom-events');
  const isCustomEventsPageOpen = currentPathname.includes('custom-events/list');
  const isEventsPageOpen = isLiveEventsPageOpen || isCustomEventsPageOpen;

  useEffect(() => {
    if (isLiveEventsPageOpen) {
      toggleSidepanel(false);
    }
  }, [isLiveEventsPageOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <NestedNavigationMenu
      isNavigationSidepanelOpen={isNavigationSidepanelOpen}
      triggerText="Events"
      isActive={isEventsPageOpen}
      triggerIcon={<EventsMenuIconV3 active={isEventsPageOpen} />}
      tooltipContent={
        <SidepanelNavlinkTooltip
          icon={<EventsMenuIconV3 active />}
          description="User and system actions tracked for  analysis"
        />
      }
    >
      <SidepanelNavLink
        isNavigationSidepanelOpen
        path="events"
        text="Live Events"
        icon={<EventsMenuIconV3 active={isLiveEventsPageOpen} />}
        tooltipContent={
          <SidepanelNavlinkTooltip icon={<EventsMenuIconV3 active />} description="Real time events dashboard" />
        }
      />
      <DropdownMenuSeparator className="px-2 my-2" />
      <SidepanelNavLink
        isNavigationSidepanelOpen
        path="custom-events/list"
        text="Custom Events"
        icon={
          <LucidIconWrapper
            name="mouse-pointer-click"
            stroke={isCustomEventsPageOpen ? CustomSvgIconActiveModeStroke : undefined}
          />
        }
        tooltipContent={
          <SidepanelNavlinkTooltip
            icon={
              <LucidIconWrapper
                name="mouse-pointer-click"
                stroke={isCustomEventsPageOpen ? CustomSvgIconActiveModeStroke : undefined}
              />
            }
            description="Group events together for better analysis"
          />
        }
      />
    </NestedNavigationMenu>
  );
}
