import { DropdownMenuSeparator } from '@src/client/ui-library/dropdown';
import { UsersIconV3 } from '@src/client/ui-library/icons/v3';
import { CustomSvgIconActiveModeStroke } from '@src/client/ui-library/icons/v3/constants';

import LucidIconWrapper from '../lucid-icon-wrapper';
import NestedNavigationMenu from './NestedNavigationMenu';
import SidepanelNavLink from './SidepanelNavLink';
import SidepanelNavlinkTooltip from './SidepanelNavlinkTooltip';

export default function UserMenu({
  isNavigationSidepanelOpen,
  currentPathname,
}: {
  isNavigationSidepanelOpen: boolean;
  currentPathname: string;
}) {
  const isProfilePageOpen = currentPathname.includes('user-profiles/list');
  const isCohortPageOpen = currentPathname.includes('cohort/list');
  const isUserPageOpen = isProfilePageOpen || isCohortPageOpen;

  return (
    <NestedNavigationMenu
      isNavigationSidepanelOpen={isNavigationSidepanelOpen}
      triggerText="Users"
      isActive={isUserPageOpen}
      triggerIcon={<UsersIconV3 active={isUserPageOpen} />}
      tooltipContent={
        <SidepanelNavlinkTooltip
          icon={<UsersIconV3 active />}
          description="Create customer segmentations, view user level journeys"
        />
      }
    >
      <SidepanelNavLink
        isNavigationSidepanelOpen
        path="cohort/list"
        text="Cohorts"
        icon={<UsersIconV3 active={isCohortPageOpen} />}
        tooltipContent={
          <SidepanelNavlinkTooltip
            icon={<UsersIconV3 active />}
            description="Group users by shared characteristics for personalized targeting."
          />
        }
      />
      <DropdownMenuSeparator className="px-2 my-2" />
      <SidepanelNavLink
        isNavigationSidepanelOpen
        path="user-profiles/list"
        text="Profile"
        icon={<LucidIconWrapper name="user" stroke={isProfilePageOpen ? CustomSvgIconActiveModeStroke : undefined} />}
        tooltipContent={
          <SidepanelNavlinkTooltip
            icon={<LucidIconWrapper name="user" stroke={CustomSvgIconActiveModeStroke} />}
            description="Access detailed, organized user profiles for data-driven decisions."
          />
        }
      />
    </NestedNavigationMenu>
  );
}
