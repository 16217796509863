import { ELEVATED_ACCESS_ROLES } from '@src/client/components/site-navbar/constants';
import { useIsOnboardingCreatedWorkspace } from '@src/client/helpers/reports/hooks';
import { useUserInfoQuery, useWorkspaceSelector } from '@src/client/hooks';
import { ErrorTags, EventNames } from '@src/client/lib/analytics/events';
import Tracker from '@src/client/lib/analytics/tracker';
import { isLengthyArray } from '@src/client/lib/utils';
import { ScreenNames } from '@src/client/routes/data';
import { SpinLoader } from '@src/client/ui-library/loaders';
import Tabs from '@src/client/ui-library/tabs';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import LoginBg from '../../components/login-bg';
import { getWorkspacesByOrg } from './api/mutations';
import { CreateWorkspaceSection } from './components/CreateWorkspaceSection';
import { SelectWorkspaceSection } from './components/SelectWorkspaceSection';
import { WorkspaceByOrg } from './types';

function CreateNewWorkspace() {
  const [availableWorkspaces, setAvailableWorkspaces] = useState<WorkspaceByOrg[]>([]);
  const handleWorkspaceChange = useWorkspaceSelector();
  const { data } = useUserInfoQuery();

  const isOnboardingFlowWs = useIsOnboardingCreatedWorkspace();
  const isInOnboardingFlow =
    (data?.workspaces.length === 1 && data?.workspaces[0]?.id === 'demo-ecommerce-app') || isOnboardingFlowWs;
  const isWorkspaceCreationEnabled = data && (ELEVATED_ACCESS_ROLES.includes(data.roleName) || isInOnboardingFlow);

  const workspaceByOrg = useMutation(getWorkspacesByOrg, {
    onSuccess: (res) => {
      setAvailableWorkspaces(res);
    },
    onError: (err: Error) => {
      Tracker.trackError(err, ErrorTags.FETCH_WORKSPACES_ORG);
    },
  });

  useEffect(() => {
    workspaceByOrg.mutateAsync();
    Tracker.setCurrentScreenName(ScreenNames.CreateWorkspace);
    Tracker.trackEvent(
      EventNames.PAGE_VIEW,
      {},
      {
        pathName: location.pathname, // eslint-disable-line no-restricted-globals
      },
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const tabs = [
    {
      key: 'select',
      label: 'Choose work space',
      children: (
        <SelectWorkspaceSection workspaces={availableWorkspaces} onWsChange={handleWorkspaceChange} userInfo={data} />
      ),
    },
    ...(isWorkspaceCreationEnabled
      ? [
          {
            key: 'create',
            label: 'Create new workspace',
            children: (
              <CreateWorkspaceSection
                onWsChange={handleWorkspaceChange}
                isLimitExhausted={availableWorkspaces.length > 2}
                isOnboardingFlow={isInOnboardingFlow}
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <LoginBg>
      <div className="flex items-center justify-center flex-col p-4">
        <div style={{ width: '550px' }} className="p-8 rounded-2xl max-h-[480px] bg-background">
          <p className="font-extrabold dark:text-foreground text-xl text-center mt-4">
            💼&nbsp;&nbsp;
            {isLengthyArray(availableWorkspaces)
              ? `Select your workspace${isWorkspaceCreationEnabled ? ' or create new' : ''}`
              : 'Create new workspace'}
          </p>
          {workspaceByOrg.isLoading ? (
            <div className="flex flex-col justify-center h-full">
              <SpinLoader relative />
            </div>
          ) : isLengthyArray(availableWorkspaces) ? (
            <Tabs
              defaultActiveKey={isWorkspaceCreationEnabled ? 'create' : 'select'}
              tabItems={tabs}
              onChange={(key) => {
                Tracker.trackEvent(EventNames.CREATE_WORKSPACE_TAB_SWITCH, {}, { createWorkspaceActiveTab: key });
              }}
              tabListClassName="mt-6 px-8"
            />
          ) : (
            <CreateWorkspaceSection onWsChange={handleWorkspaceChange} isOnboardingFlow={isInOnboardingFlow} />
          )}
        </div>
      </div>
    </LoginBg>
  );
}

export default CreateNewWorkspace;
