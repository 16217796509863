import { FunnelBarGraphData } from '@src/client/helpers/reports/types';
import { GetReportUsersResData, ReportType } from '@src/client/lib/api/types/response';
import { atom } from 'recoil';

export type UserViewFunnelStepMetaInfo = {
  stepCombination: string;
  isDropoff: boolean;
  canViewUserActivity: boolean;
  stepData: FunnelBarGraphData;
  dropoffUserCount?: number;
};

export const showUserViewAtom = atom<{ show: boolean; reportType: ReportType } | undefined>({
  key: 'showUserViewAtom',
  default: undefined,
});

export const userViewFunnelStepMetaInfoState = atom<UserViewFunnelStepMetaInfo | undefined>({
  key: 'userViewFunnelStepMetaInfoState',
  default: undefined,
});

export const reportUserViewDataState = atom<GetReportUsersResData[]>({
  key: 'reportUserViewDataState',
  default: [],
});

export const reportUserViewErrorState = atom<Error | null | undefined>({
  key: 'reportUserViewErrorState',
  default: null,
});

export const reportUserViewLoadingState = atom<boolean>({
  key: 'reportUserViewLoadingState',
  default: false,
});

export const reportuserViewDataOffsetState = atom<number>({
  key: 'reportuserViewDataOffsetState',
  default: 0,
});

export const showReportCreateCohortViewAtom = atom<boolean>({
  key: 'showReportCreateCohortViewAtom',
  default: false,
});

export const reportCreateCohortErrorState = atom<Error | null | undefined>({
  key: 'reportCreateCohortErrorState',
  default: null,
});

export const reportuserViewDataCanFetchMoreState = atom<boolean>({
  key: 'reportuserViewDataCanFetchMoreState',
  default: true,
});

export const isReportSavedState = atom<boolean>({
  key: 'isReportSavedState',
  default: true,
});
